import React, {useEffect, useMemo, useState} from 'react';
import {css} from "@emotion/css";
import {nonsequiturWords} from "./NonsequiturMaker";
import {Button, IconButton, Typography, useTheme} from "@mui/material";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import RefreshIcon from '@mui/icons-material/Refresh';
import {shuffle} from "lodash-es";
import {kButtonTitles} from "./ButtonTitles";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from "copy-to-clipboard";

//==============================================================================
export function Word(props) {
  const {word, locked, onLockClick, onNewClick, pos } = props;
  const theme = useTheme();
  const title = `Lock the ${pos}`;
  return (
    <div style={{
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyItems: "space-between",
    }}>
      <IconButton onClick={onNewClick} color="success" disabled={locked}
      title={title} sx={{
        marginLeft: "1em",
        [theme.breakpoints.down('md')]: {
          marginLeft: "0.25em",
        },
      }}>
        {<RefreshIcon sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: "0.8em",
          },
        }}/>}
      </IconButton>
      <Typography component="span" variant="span" sx={{
        flex: 1,
        fontFamily: '"Playfair", serif',
        fontWeight: 400,
        color: locked ? "goldenrod" : theme.palette.text.primary,
        fontSize: "4em",
        [theme.breakpoints.down('md')]: {
          fontSize: "2em",
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: "1.5em",
        },
        // lineHeight: "80%",
      }}>{word}</Typography>
      <IconButton onClick={onLockClick} sx={{
        color: locked ? "goldenrod" : theme.palette.text.primary,
        marginRight: "1em",
        [theme.breakpoints.down('md')]: {
          marginRight: "0.25em",
        },
      }}>
        {locked ? <LockIcon sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: "0.8em",
          },
        }}/> : <LockOpenIcon sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: "0.8em",
          },
        }}/>}
      </IconButton>
    </div>
  );
}

//==============================================================================
export function Nonsequitur(props) {
  // State
  const [serial, setSerial] = useState(0);
  const [adjLock, setAdjLock] = useState(false);
  const [nounLock, setNounLock] = useState(false);
  const [words, setWords] = useState(nonsequiturWords());
  const [btIndex, setBtIndex] = useState(0);

  // Memos
  const theme = useTheme();
  const buttonTitles = useMemo(() => {
    let shuffled = shuffle(kButtonTitles);
    let newIndex = shuffled.indexOf("again");
    setBtIndex(newIndex);
    return shuffled;
  }, []);
  const buttonTitle = buttonTitles[btIndex];

  // Effects
  useEffect(() => {
    let newWords = nonsequiturWords();
    if (adjLock)
      newWords[0] = words[0];
    if (nounLock)
      newWords[1] = words[1];
    setWords(newWords);
  }, [serial, btIndex]);

  // Event handlers
  function onNewClick() {
    setSerial(serial + 1);
    setBtIndex((btIndex + 1) % kButtonTitles.length);
  }
  function onAdjLockClick() { setAdjLock(!adjLock); }
  function onNounLockClick() { setNounLock(!nounLock); }
  function onAdjNewClick() {
    setWords([nonsequiturWords()[0], words[1]]);
  }
  function onNounNewClick() {
    setWords([words[0], nonsequiturWords()[1]]);
  }
  function onCopyButtonClick() {
    copy(words.join(" "));
  }

  return (
    <div className={css`
      height: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 1;
    `}>
      <Word word={words[0]} locked={adjLock}
            onNewClick={onAdjNewClick}
            onLockClick={onAdjLockClick}/>
      <br/>
      <Word word={words[1]} locked={nounLock}
            onNewClick={onNounNewClick}
            onLockClick={onNounLockClick}/>
      <br/>
      <Button onClick={onNewClick} disabled={adjLock && nounLock} sx={{
        color: "#d40000",
        marginTop: "2em",
        fontSize: "2em",
        [theme.breakpoints.down('sm')]: {
          fontSize: "1.25em",
        },
        paddingLeft: "1em",
        paddingRight: "1em",
      }}>
        {buttonTitle}
      </Button>
      <br/>
      <Button startIcon={<ContentCopyIcon/>} onClick={onCopyButtonClick} sx={{
        fontSize: "1em",
        paddingLeft: "1em",
        paddingRight: "1em",
        color: theme.palette.text.primary,
        [theme.breakpoints.down('sm')]: {
          fontSize: "0.9em",
        },
      }}>Copy</Button>
    </div>
  );
}

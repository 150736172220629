import React, {useMemo, useState} from "react";
import {Box, createTheme, CssBaseline, Link, ThemeProvider, useMediaQuery, useTheme}
  from "@mui/material";
import Header, {DarkModeButton} from "./Header";
import {AboutDialog, FAQDialog, CreditsDialog} from "./Dialogs";
import {Nonsequitur} from "../Nonseq/Nonsequitur";
import {css} from "@emotion/css";
import rgDk from "./image/RegenesisDarkMode.png";
import rgLt from "./image/RegenesisLightMode.png";
import "../index.css";

//==============================================================================
export function Footer(props) {
  const { onLink } = props;
  return (
    <div className={css`
        margin: 0 auto 0 auto;
        max-width: 12em;
        position: fixed;
        bottom: 1em;
        left: 1em;
        right: 1em;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      `}>
      <Link title="More than you want to know" href="#"
            onClick={() => onLink("about")}>About</Link>&nbsp;|&nbsp;
      <Link title="Even more than you wanted to know" href="#"
            onClick={() => onLink("faq")}>FAQ</Link>&nbsp;|&nbsp;
      <Link title="Important stuff" href="#"
            onClick={() => onLink("credits")}>Credits</Link>
    </div>
  );
}

//==============================================================================
export function RegenesisLogo(props) {
  const theme = useTheme();
  return (
    <a href="https://www.linkedin.com/in/bryan-ressler-94b86040/" className={css`
        position: fixed;
        left: 1.33em;
        top: 1.2em;
        //width: 100px;
      `}>
      <Box sx={{
        display: "inline-block",
        width: "100px",
        [theme.breakpoints.down('sm')]: {
          width: "75px",
        },
      }}>
        <img src={theme.palette.mode === "dark" ? rgDk : rgLt} alt="regenesis.art"
         title="Bryan Ressler is Regenesis.art."
        style={{ maxWidth: "100%" }}/>
      </Box>
    </a>
  );
}

//==============================================================================
function App() {
  // State
  let stored = localStorage.getItem("inverted");
  const [inverted, setInverted] = useState(stored ? stored === "true" : false);
  const [dialogOpen, setDialogOpen] = useState("none");

  // Use the system preference, remember if user inverted that
  const mediaPrefersDark = useMediaQuery('(prefers-color-scheme: dark)',
    { defaultMatches: true }) ? "dark" : "light";
  const mode = mediaPrefersDark ?
    inverted ? "light" : "dark" :
    inverted ? "dark" : "light";
  const theme = useMemo(() =>
    createTheme({
      palette: {
        mode: mode
      },
      typography: {
        //fontFamily: '"Roboto Condensed", "Helvetica Narrow", "Arial Narrow", sans-serif'
        fontFamily: '"Roboto", "Helvetica Neue", "Arial", sans-serif'
      }
    }), [mode]);
  function toggleTheme(evt) {
    const newValue = !inverted;
    localStorage.setItem("inverted", newValue ? "true" : "false");
    setInverted(newValue);
  }

  function onDialogClose() {
    setDialogOpen("none");
  }
  function onLink(link) {
    setDialogOpen(link);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header/>
      <RegenesisLogo/>
      <DarkModeButton inverted={inverted} onClick={toggleTheme} sx={{
        position: "fixed",
        right: "0.5em",
        top: "0.5em",
      }}/>
      <Nonsequitur/>
      <Footer onLink={onLink}/>
      <AboutDialog open={dialogOpen === "about"} onClose={onDialogClose}/>
      <FAQDialog open={dialogOpen === "faq"} onClose={onDialogClose}/>
      <CreditsDialog open={dialogOpen === "credits"} onClose={onDialogClose}/>
    </ThemeProvider>
  );
}

export default App;

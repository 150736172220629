export const kButtonTitles = [
  "again", "generate", "make another", "one more time",
  "oh please do press me", "hit me", "I'll take a hit",
  "again? it just keeps going", "um, doubtful, try again", "blow my mind",
  "i'm feeling lucky", "make my day", "how could i not?", "you know you want to",
  "oh may i?", "oh what's this?", "oh, so that's the joke?", "down zolotisty!",
  "what's this shiny thing?", "ok i've gotta know", "what could go wrong?",
  "admittedly, a little scary", "read my mind", "suggest", "proffer",
  "begin again", "renew", "shake it up", "chirk up", "come again?",
  "do a repeat", "do again", "do over", "enliven", "face-lift", "freshen up",
  "have another shot", "have another go", "Try to do better", "dis-accept",
  "really?", "try harder", "is that all you got?", "show me what you got!",
  "i want to see what you got!", "improve", "innovate", "invent", "invigorate",
  "iterate", "jazz up", "make over", "modify", "modulate",
  "mutate", "overhaul", "perk up", "re-create", "re-form", "rebuild",
  "reconstruct", "recreate", "redecorate", "redesign", "redo", "reestablish",
  "refashion", "refit", "reform", "refresh", "refurbish", "regenerate",
  "regurgitate", "rehabilitate", "reincarnate", "reinspire", "reinvigorate",
  "reiterate", "rejuvenate", "rekindle", "relight", "remake", "remodel",
  "renovate", "repair", "replace", "reprise", "reproduce", "resay", "reshape",
  "restate", "restructure", "resurrect", "resuscitate", "revamp", "revise",
  "revitalize", "revive", "rewarm", "set straight", "sharpen",
  "shift the scene", "shuffle the cards", "start all over", "stimulate",
  "stir the embers", "subvert", "take up again", "transform", "turn the tables",
  "turn upside down", "update", "vary", "warm over",
  "warm up", "work a change",
  "air out", "convulse", "doll up", "bedazzle!", "gussy up", "smarten up",
  "trick out", "oh snap", "quae non facit sensum", "surprise me",
  "i like surprises", "never a dull moment", "wait, there's more?",
  "it's always on", "what does this do?", "consult the oracle",
  "read the tea leaves", "perform unspeakable calculations", "go boldly",
  "surely you can do better", "i didn't like that one either", "oh boy",
  "i can do this all day", "spike me!", "oh that won't do", "someone's bored",
  "don't feel guilty", "everyone else pressed it", "it can't hurt",
  "do not press", "possibly benign action", "usually not harmful",
  "just how many are there?", "it goes on and on", "is this real?",
  "it can't be",
];

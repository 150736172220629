import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App/App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App sx={{
      flex: 1,
      height: "100%",
      width: "100%"
    }} />
  </React.StrictMode>
);

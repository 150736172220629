import React from "react";
import {AppBar, Box, IconButton, Toolbar, Typography, useTheme}
  from "@mui/material";
import TonalityIcon from "@mui/icons-material/Tonality";

//------------------------------------------------------------------------------
export function DarkModeButton(props) {
  const { inverted, ...rest } = props;
  return (
    <IconButton color="inherit" aria-label="Toggle dark mode"
                title="Toggle dark mode" {...rest}>
      <TonalityIcon sx={{
        transform: `rotate(${inverted ? -180 : 0}deg)`,
        transition: "transform 0.25s ease-out"
      }} />
    </IconButton>
  );
}

//==============================================================================
export default function Header(props) {
  const theme = useTheme();

  return (
    <Box id='header' {...props}>
      <AppBar position="static" elevation={0}
              style={{
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary,
              }}>
        <Toolbar sx={{
          backgroundColor: theme.palette.background.default,
        }}>
          <Typography variant="h6" component="div"
                      title='"it does not follow"'
                      sx={{
            fontFamily: '"Playfair", serif',
            fontStyle: "italic",
            flexGrow: 1,
            textAlign: "center",
          }}>
            {/*{document.title} <Version/>*/}
            non sequitur
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

import React from 'react';
import {ScrollDialog} from "./ScrollDialog";
import {adjectiveCount, nounCount} from "../Nonseq/NonsequiturMaker";
import {Link} from "@mui/material";
import styled from "@emotion/styled";

//==============================================================================
export function AboutDialog(props) {
  const { open, onClose } = props;
  return (
    <ScrollDialog title="About" open={open} onClose={onClose}>
      <p>A <em>non sequitur</em> is simply a series of words that don't follow.
      I've always found them funny, and finally got around to having a reason
      to write a simple two-word <em>non sequitur</em> generator. The result was
      entertaining enough that I decided to spend a day writing a site for it.</p>
      <p>The generator has a list of {adjectiveCount().toLocaleString()}&thinsp;adjectives
      and {nounCount().toLocaleString()}&thinsp;nouns. It picks an adjective
      at random, then a noun at random, and sticks them together. No AIs were
      consulted in the formation of any of these <em>non sequiturs</em>. However
      you are encouraged to ask the AIs for the meanings if you want to f**k
      with them.</p>
      <p>Because it uses uniform random numbers and knows nothing about the
      words it is putting together, the generator <em>might</em> generate a <em>sequitur</em>.
      Hey, <em>caveat emptor</em>. And <Link href="https://googlethatforyou.com?q=YGWYPF%20meaning">YGWYPF</Link>.</p>
    </ScrollDialog>
  );
}

const Q = styled.span`
  color: ${props => props.theme.palette.success.main}
`;

const BulletList = styled.ul`
  padding-left: 2em;
`;

//==============================================================================
export function FAQDialog(props) {
  const { open, onClose } = props;
  return (
    <ScrollDialog title="Frequently Asked Questions" open={open} onClose={onClose}>
      <BulletList>
        <li><Q>Can I use this for my [gamer tag | cover band | baby name, etc.]?</Q><br/>
          Oh hell yes! You don't even have to credit me, and how could you?
        </li>
        <li><Q>Are all these real words?</Q><br/>
          These are all presumably real words, although there's some crufty
          stuff in there that looks like abbreviations. I am just grateful for
          the wonderful part-of-speech resource from which the words came, and
          chose to preserve the fidelity of
          that <Link href="https://github.com/elitejake/Moby-Project"> input
            data set</Link>. (See the Credits.)
        </li>
        <li><Q>What do these words mean?</Q><br/>
          Hey, that's your job. You could ask an AI, or you could
          go <Link href="https://dict.org">old skool</Link>.
        </li>
        <li><Q>How many words are there?</Q><br/>
          There are {adjectiveCount().toLocaleString()}&thinsp;adjectives
          and {nounCount().toLocaleString()}&thinsp;nouns. That means that there
          are {(adjectiveCount() * nounCount()).toLocaleString()}&thinsp;possible
          word combinations. So keep pressing that button.
        </li>
        <li><Q>What font is used for the words?</Q><br/>
          The words are shown in <Link href="https://fonts.google.com/specimen/Playfair+Display?query=playfair">Playfair Display 400 Regular</Link>.
          The same face in italic form is used for the site title.
        </li>
        <li><Q>I'm deeply offended. Where do I register my complaint?</Q><br/>
          <Link href="https://www.youtube.com/watch?v=5hfYJsQAhl0">Here</Link>.
          (GitHub Copilot, an AI trained on a lot of code, immediately suggested
          that link, so I wash my hands of it.) Or you could just press the
          button and get a different pair of words that is offensive in a new way.
        </li>
        <li><Q>How many <em>non sequiturs</em> has your site generated?</Q><br/>
          Exactly {Math.floor(Math.random() * adjectiveCount() * nounCount()).toLocaleString()}.
        </li>
        <li><Q>Did you make up that figure right now?</Q><br/>
          Yes.
        </li>
        <li><Q>How uniform is your random number generator?</Q><br/>
          You need to get out more.
        </li>
      </BulletList>
    </ScrollDialog>
  );
}

//==============================================================================
export function CreditsDialog(props) {
  const { open, onClose } = props;
  return (
    <ScrollDialog title="Credits" open={open} onClose={onClose}>
      <BulletList>
        <li>Site: <Link href="https://www.linkedin.com/in/bryan-ressler-94b86040/">Bryan Ressler</Link>,
          aka Regenesis.art.
        </li>
        <li>Source data set: <Link href="https://en.wikipedia.org/wiki/Grady_Ward">Grady Ward</Link> via <Link href="https://github.com/elitejake/Moby-Project">The Moby Project</Link>.
        </li>
        <li>The word pairs generated by this site are hereby released into the
          public domain for whatever purpose.
        </li>
      </BulletList>
    </ScrollDialog>
  );
}
